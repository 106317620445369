
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { wuRequest } from '@/service'

import { url } from '@/service/config'
import router from '@/router'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: {},
    setup() {
        let t = reactive<any>({
            data: {},
            menu: [
                { id: 40, name: '高田简介', en_name: 'About GTCHAIR', active: true },
                { id: 41, name: '品牌活动', en_name: 'Events', active: false },
                { id: 42, name: '权威认证', en_name: 'Certificates', active: false }
            ],
            //权威中心数据
            qw: [],
            url
        })
        const route = useRoute().query

        onMounted(async () => {
            // 保持菜单更新一直在顶
            document.body.scrollTop = document.documentElement.scrollTop = 0
            let res = await wuRequest.get({
                url: '/walkgt'
            })
            if (res.data.code === 10001) {
                t.data = res.data.data.data as any
                t.data.listDesc.权威中心.map((item: any) => {
                    item.active = false
                })
            } else {
                ElMessageBox.alert('数据请求错误，请稍后重试', '数据异常', {
                    confirmButtonText: 'OK'
                })
                return false
            }
            // 主要是判断过来的id，属于哪个菜单。需要激活指定的菜单
            if (!route.id || route.id == '40') {
                t.menu.map((item: any) => {
                    if (item.id === 40) {
                        item.active = true
                    } else {
                        item.active = false
                    }
                })
            } else if (route.id == '41') {
                t.menu.map((item: any) => {
                    if (item.id === 41) {
                        item.active = true
                    } else {
                        item.active = false
                    }
                })
            } else if (route.id == '42') {
                t.menu.map((item: any) => {
                    if (item.id === 42) {
                        item.active = true
                    } else {
                        item.active = false
                    }
                })
            }
        })
        const changeMenu = (id: number) => {
            t.menu.map((item: any, index: any) => {
                if (index === id) {
                    item.active = true
                } else {
                    item.active = false
                }
            })
        }
        const showDesc = (index: number) => {
            if (t.data.listDesc.权威中心[index].active) {
                alert(1)
                t.data.listDesc.权威中心[index].active = false
            } else {
                t.data.listDesc.权威中心.map((item: any) => {
                    item.active = false
                })
                t.data.listDesc.权威中心[index].active = true
            }
        }
        const linkTo = (id: number) => {
            router.push('/pp-activity/' + id)
        }

        return { ...toRefs(t), changeMenu, showDesc, linkTo }
    }
})
